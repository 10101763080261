import * as Sentry from '@sentry/browser';

import logger from './logger';

// https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/

export const init = () => {
  /* eslint no-underscore-dangle: 0 */
  const dsn = window.__RUNTIME_CONFIG__.SENTRY_DSN;
  const sentryEnv = window.__RUNTIME_CONFIG__.SENTRY_ENV;
  let sampleRate = Number(window.__RUNTIME_CONFIG__.SENTRY_SAMPLE_RATE);
  if (Number.isNaN(sampleRate) || sampleRate < 0 || sampleRate > 1) {
    sampleRate = 1;
  }
  if (!dsn) return;
  try {
    Sentry.init({
      dsn,
      release: process.env.REACT_APP_VERSION || 'unknown',
      environment: sentryEnv || 'unknown',
      ignoreErrors: [],
      sampleRate,
    });
  } catch (e) {
    logger.error(e);
  }
};

export const setSentryUser = (user: Pick<Sentry.User, 'id'> | null) => {
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
};

export const reportErrorWithScope = (error: any, extraInfo?: any) => {
  Sentry.withScope((scope) => {
    if (extraInfo) scope.setExtras(extraInfo);
    Sentry.captureException(error);
  });
};

export const reportError = (
  error: any,
  options?: {
    extraInfo?: any;
    fingerprint?: string[];
    triggerAlert?: boolean;
    tags?: {
      [key: string]: string | number | boolean;
    };
  }
) => {
  const { extraInfo, fingerprint, tags = {}, triggerAlert } = options || {};
  Sentry.captureException(error, (scope) => {
    if (extraInfo)
      scope.setExtras({
        ...extraInfo,
        error,
      });
    if (fingerprint) {
      scope.setFingerprint(['{{ default }} ', ...fingerprint]);
    }
    scope.setTags(triggerAlert ? { ...tags, alert: true } : tags);
    return scope;
  });
};
